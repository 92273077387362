import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { isProd } from "../../util/Env";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import { isWeekPeriod } from "../../util/Period";
import { Menu, Grid, Drawer } from "antd";

const { useBreakpoint } = Grid;

const SidebarContent = ({ isDrawerOpen, closeDrawer }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();

  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);

  const notifications = useSelector(notificationSelector);
  const unreadNotifications =
    notifications && notifications?.data
      ? notifications?.data?.filter((n) => !n.read).length
      : 0;

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };

  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split("/")[1];

  const getMenuItemClass = (themeType, screensLg) =>
    screensLg
      ? themeType === "THEME_TYPE_DARK"
        ? "bp-menuitem-dark-hover"
        : "bp-menuitem-lite-hover"
      : themeType === "THEME_TYPE_DARK"
      ? "bp-menuitem-dark-hover"
      : "bp-menuitem-lite-hover bp-menuitem-lite";

  const getLinkClass = (themeType) =>
    themeType === "THEME_TYPE_DARK"
      ? "bp-menuitem-text-dark"
      : "bp-menuitem-text-lite";

  const onSubMenuClick = (key, route) => {
    history.push(`/${key}/${route}`);
  };

  const menuContent = (
    <>
      {[
        {
          key: "main",
          to: "/dashboard",
          label: <IntlMessages id="sidebar.home" />,
        },
        {
          key: "bist/global",
          to: "/bist/global",
          label: screens.xl
            ? "Global Piyasalar"
            : screens.lg
            ? "Global"
            : "Global Piyasalar",
        },
        { key: "index/home", to: "/index/home", label: "Sentiment" },
      ].map(({ key, to, label }) => (
        <Menu.SubMenu
          key={key}
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          className={
            screens.lg
              ? "bp-submenu-noafter"
              : themeType === "THEME_TYPE_DARK"
              ? "bp-submenu-noafter"
              : "bp-submenu-noafter bp-submenu-lite-mobil"
          }
          title={
            <Link to={to} style={{ color: "inherit", width: "100%" }}>
              {label}
            </Link>
          }
        />
      ))}

      <Menu.SubMenu
        key="bist"
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <Link
            to={"/bist/lines"}
            style={{
              color: screens.lg
                ? "#fff"
                : themeType === "THEME_TYPE_DARK"
                ? "#fff"
                : "#444",
            }}
          >
            <span>
              <IntlMessages id="sidebar.bist" />
            </span>
          </Link>
        }
        className={
          screens.lg
            ? ""
            : themeType === "THEME_TYPE_LITE" && "bp-menuitem-lite-mobil"
        }
      >
        {[
          { key: "bist/lines", to: "/bist/lines", label: "Hisseler" },
          { key: "bist/follower", to: "/bist/follower", label: "Radar" },
          {
            key: "bist/explore",
            to: "/bist/explore",
            label: <IntlMessages id="sidebar.bist.explorer" />,
          },
          {
            key: "bist/signals",
            to: "/bist/signals",
            label: !isWeekPeriod() ? (
              "Long 10"
            ) : (
              <IntlMessages id="sidebar.bist.first7_list" />
            ),
          },
          { key: "bist/worst", to: "/bist/worst", label: "Short 10" },
          { key: "bist/timeline", to: "/bist/timeline", label: "Avcı" },
          { key: "bist/mylines", to: "/bist/mylines", label: "Portföyüm" },
        ].map(({ key, to, label }) => (
          <Menu.Item
            key={key}
            className={getMenuItemClass(themeType, screens.lg)}
          >
            <Link to={to} className={getLinkClass(themeType)}>
              {label}
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      <Menu.SubMenu
        key="history"
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <Link
            to={"/bist/history"}
            style={{
              color: screens.lg
                ? "#fff"
                : themeType === "THEME_TYPE_DARK"
                ? "#fff"
                : "#444",
            }}
          >
            <span>Tarihsel Veriler</span>
          </Link>
        }
        className={
          screens.lg
            ? ""
            : themeType === "THEME_TYPE_LITE" && "bp-menuitem-lite-mobil"
        }
      >
        {[
          { key: "bist/signal", to: "/bist/history", label: "Semboller" },
          {
            key: "bist/trades",
            to: "/bist/trades",
            label: <IntlMessages id="sidebar.bist.first_list_history" />,
          },
          {
            key: "bist/shortTrades",
            to: "/bist/shortTrades",
            label: <IntlMessages id="sidebar.bist.last_list_history" />,
          },
        ].map(({ key, to, label }) => (
          <Menu.Item
            key={key}
            className={getMenuItemClass(themeType, screens.lg)}
          >
            <Link to={to} className={getLinkClass(themeType)}>
              {label}
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      {[
        // { key: "bist/history", to: "/bist/history", label: "Tarihsel Veriler" },
        { key: "bist/pairLines", to: "/bist/pairLines", label: "Pair" },
      ].map(({ key, to, label }) => (
        <Menu.SubMenu
          key={key}
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          className={
            screens.lg
              ? "bp-submenu-noafter"
              : themeType === "THEME_TYPE_DARK"
              ? "bp-submenu-noafter"
              : "bp-submenu-noafter bp-submenu-lite-mobil"
          }
          title={
            <Link to={to} style={{ color: "inherit", width: "100%" }}>
              {label}
            </Link>
          }
        />
      ))}

      <Menu.SubMenu
        key="exchanger"
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <Link
            to={"/exchanger/signal"}
            style={{
              color: screens.lg
                ? "#fff"
                : themeType === "THEME_TYPE_DARK"
                ? "#fff"
                : "#444",
            }}
          >
            <span>Takascı</span>
          </Link>
        }
        className={
          screens.lg
            ? ""
            : themeType === "THEME_TYPE_LITE" && "bp-menuitem-lite-mobil"
        }
      >
        {[
          { key: "exchanger/signal", to: "/exchanger/signal", label: "Sinyal" },
          { key: "exchanger/symbol", to: "/exchanger/symbol", label: "Sembol" },
          {
            key: "exchanger/corporate",
            to: "/exchanger/corporate",
            label: "Kurum",
          },
        ].map(({ key, to, label }) => (
          <Menu.Item
            key={key}
            className={getMenuItemClass(themeType, screens.lg)}
          >
            <Link to={to} className={getLinkClass(themeType)}>
              {label}
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      <Menu.SubMenu
        key="ai"
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <Link
            to={"/ai/tbot"}
            style={{
              color: screens.lg
                ? "#fff"
                : themeType === "THEME_TYPE_DARK"
                ? "#fff"
                : "#444",
            }}
          >
            <span> AI Bot</span>
          </Link>
        }
        className={
          screens.lg
            ? ""
            : themeType === "THEME_TYPE_LITE" && "bp-menuitem-lite-mobil"
        }
      >
        <Menu.Item
          key="/ai/tbot"
          className={getMenuItemClass(themeType, screens.lg)}
        >
          <Link to="/ai/tbot" className={getLinkClass(themeType)}>
            TBOT
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/ai/sbot"
          className={getMenuItemClass(themeType, screens.lg)}
        >
          <Link to="/ai/sbot" className={getLinkClass(themeType)}>
            SBOT
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
    </>
  );

  return (
    <>
      {screens.lg ? (
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          mode="horizontal"
          disabledOverflow={true}
        >
          {menuContent}
        </Menu>
      ) : (
        <Drawer
          placement="left"
          closable={true}
          onClose={closeDrawer}
          visible={isDrawerOpen}
          headerStyle={{ display: "none" }}
          bodyStyle={{
            backgroundColor: themeType === "THEME_TYPE_LITE" ? "" : "#232A3D",
          }}
        >
          <div>
            <div
              style={{
                height: 62,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "2px solid #eee",
                backgroundImage:
                  themeType === "THEME_TYPE_LITE"
                    ? "linear-gradient(to top, rgb(9, 32, 63) 0%, #0061ad 100%)"
                    : "linear-gradient(#0e008c 0%, #0e0066 100%)",
              }}
            >
              <img
                alt="drawer-logo"
                src="/assets/images/logo.png?v=3"
                style={{ height: 50, width: 231 }}
              />
            </div>

            <div
              style={{
                height: "calc(100vh - 66px)",
                position: "relative",
                overflowX: "hidden",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === "THEME_TYPE_LITE" ? "lite" : "dark"}
                mode="inline"
                style={{
                  paddingTop: 18,
                  overflowX: "hidden",
                  overflowY: "auto",
                  backgroundColor:
                    themeType === "THEME_TYPE_LITE" ? "" : "#232A3D",
                  fontWeight: 600,
                }}
              >
                {menuContent}

                {!isProd() && (
                  <Menu.SubMenu
                    key="messages"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    className={
                      screens.lg
                        ? "bp-submenu-noafter"
                        : themeType === "THEME_TYPE_DARK"
                        ? "bp-submenu-noafter"
                        : "bp-submenu-noafter bp-submenu-lite-mobil"
                    }
                    title={
                      <Link
                        to="/messages"
                        style={{ color: "inherit", width: "100%" }}
                      >
                        Mesajlar
                      </Link>
                    }
                  />
                )}

                <Menu.SubMenu
                  key="chat"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  className={
                    screens.lg
                      ? "bp-submenu-noafter"
                      : themeType === "THEME_TYPE_DARK"
                      ? "bp-submenu-noafter"
                      : "bp-submenu-noafter bp-submenu-lite-mobil"
                  }
                  title={
                    <Link
                      to="/chat"
                      style={{ color: "inherit", width: "100%" }}
                    >
                      Sohbet
                    </Link>
                  }
                />

                <Menu.SubMenu
                  key="bulletins"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  className={
                    screens.lg
                      ? "bp-submenu-noafter"
                      : themeType === "THEME_TYPE_DARK"
                      ? "bp-submenu-noafter"
                      : "bp-submenu-noafter bp-submenu-lite-mobil"
                  }
                  title={
                    <Link
                      to="/bulletins"
                      style={{ color: "inherit", width: "100%" }}
                    >
                      <IntlMessages id="sidebar.bulletins" />
                    </Link>
                  }
                />

                <Menu.SubMenu
                  key="notifications"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  className={
                    screens.lg
                      ? "bp-submenu-noafter"
                      : themeType === "THEME_TYPE_DARK"
                      ? "bp-submenu-noafter"
                      : "bp-submenu-noafter bp-submenu-lite-mobil"
                  }
                  title={
                    <Link
                      to="/notifications"
                      style={{ color: "inherit", width: "100%" }}
                    >
                      <IntlMessages id="sidebar.notifcations" />{" "}
                      {unreadNotifications > 0 && (
                        <span className="notification-count-badge">
                          {unreadNotifications}{" "}
                        </span>
                      )}
                    </Link>
                  }
                />

                <Menu.SubMenu
                  key="account"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  className={
                    screens.lg
                      ? "bp-submenu-noafter"
                      : themeType === "THEME_TYPE_DARK"
                      ? "bp-submenu-noafter"
                      : "bp-submenu-noafter bp-submenu-lite-mobil"
                  }
                  title={
                    <Link
                      to="/account"
                      style={{ color: "inherit", width: "100%" }}
                    >
                      <IntlMessages id="sidebar.account" />
                    </Link>
                  }
                />

                <Menu.SubMenu
                  key="help"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  className={
                    screens.lg
                      ? "bp-submenu-noafter"
                      : themeType === "THEME_TYPE_DARK"
                      ? "bp-submenu-noafter"
                      : "bp-submenu-noafter bp-submenu-lite-mobil"
                  }
                  title={
                    <Link
                      to="/help"
                      style={{ color: "inherit", width: "100%" }}
                    >
                      <IntlMessages id="sidebar.help" />
                    </Link>
                  }
                />
                <Menu.SubMenu
                  key="exit"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  className={
                    screens.lg
                      ? "bp-submenu-noafter"
                      : themeType === "THEME_TYPE_DARK"
                      ? "bp-submenu-noafter"
                      : "bp-submenu-noafter bp-submenu-lite-mobil-exit"
                  }
                  title={
                    <Link
                      to="/"
                      onClick={onLogoutClick}
                      style={{ color: "inherit", width: "100%" }}
                    >
                      <IntlMessages id="sidebar.logout" />
                    </Link>
                  }
                />
              </Menu>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default React.memo(SidebarContent);
